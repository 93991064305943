<template>
  <div class="itemInfoBox">
    <div class="itemTitle">
      {{venueInfo.supplierName}}
    </div>
    <div class="venueInfo" v-if="venueInfo.extData && venueInfo.extData.paymentName && venueInfo.extData.paymentPhoneNum">
      <div>代付费人员：<span>{{venueInfo.extData.paymentName}}</span></div>
      <div>联系方式：<span>{{venueInfo.extData.paymentPhoneNum}}</span></div>
    </div>
  </div>
</template>
<script>
    export default {
        name: 'itemCard',
        data () {
            return {
                venueInfo: {}
            }
        },
        created() {
            this.venueInfo = this.$SmartStorage.get('costItem')
        },
    }
</script>
<style lang="scss" scoped>
    @import "venueCard";
</style>
