<template>
    <section>
      <sm_header title='酒店结算' :smHleftEvent='closeWebview'></sm_header>
      <venue-card></venue-card>
      <div class="headTips" :class="isShowTips ? 'active' : ''" v-show="headTips.length">
        <p class="tipsTitle" @click="isShowTips = !isShowTips">
          注意事项
          <span>
            <svg class="icon" aria-hidden="true" style="width: .15rem;height: .15rem">
              <use :xlink:href="isShowTips ? '#iconarrow-up1' : '#iconarrow-down'"></use>
            </svg>
          </span>
        </p>
        <p v-for="(item, index) in headTips" :key="index"> {{ item }} </p>
      </div>
      <div class="quotedPrice" v-if="showInput">
        <van-field label="结算总金额" placeholder="请输入结算总金额" type="number" :required='true' :readonly="!showInput" v-model="demandData.settleAmount"></van-field>
      </div>
      <div class="quotedPrice" v-else>
        <van-field label="结算总金额" :readonly="true" v-model="demandData.settleAmount">
          <template v-slot:left-icon>
            <svg color="#13161B" class="icon searchRowSvg" aria-hidden="true" style="width: .16rem;height: .16rem">
              <use xlink:href="#icon-feiyong"></use>
            </svg>
          </template>
        </van-field>
      </div>
      <div class="quotedPrice disabledPrice">
        <van-field label="报价" :readonly="true" v-model="demandData.quoteAmount">
          <template v-slot:left-icon>
            <svg color="#909399" class="icon searchRowSvg" aria-hidden="true" style="width: .16rem;height: .16rem">
              <use xlink:href="#icon-feiyong"></use>
            </svg>
          </template>
        </van-field>
      </div>
      <div class="quotedPrice disabledPrice">
        <van-field :label="updateAmountTxt" :readonly="true" v-model="updateAmount">
          <template v-slot:left-icon>
            <svg color="#909399" class="icon searchRowSvg" aria-hidden="true" style="width: .16rem;height: .16rem">
              <use xlink:href="#icon-feiyong"></use>
            </svg>
          </template>
        </van-field>
      </div>
        <div v-for="(item, index) in demandData.itsSettleExpense" :key="index">
            <item-card :itemInfo="item" @costItemDetail="costItemDetail"></item-card>
            <div style="width: 100%;height: .08rem;background-color: #f8f8f8"></div>
        </div>

        <div class="fileTitle">
            {{fileName}}
            <FinderFrame
                    :gateway="gateway"
                    :OrderByData="OrderByData"
                    ref="FinderFrame"
                    :actions="actions"
                    :isPreview="true"
                    :h5FilePreview="false"
                    :onlyPreview="showUpdate"
                    :filterData="uploaderSettings.filterData"
                    :mappingData="uploaderSettings.mappingData"
                    :isMapping="true"
                    :uploadTimeout="120000"
                    @uploadCallback="() => {}"
            />
            <div v-if="showInvoice">
                {{filterData.catalog}}
                <FinderFrame
                        :gateway="gateway"
                        :OrderByData="OrderByData"
                        ref="FinderFrame"
                        :isPreview="true"
                        :h5FilePreview="false"
                        :onlyPreview="true"
                        :filterData="filterData"
                        :isMapping="true"
                        :uploadTimeout="120000"
                        @uploadCallback="() => {}"
                />
            </div>
        </div>
        <div class="confirmCostFooter" v-show="isShowFooter">
          <div class="largeBtn2" v-for="(item, index) in btnList" :key="index" :style="{width: (90 / btnList.length)+ '%'}"
             @click="onceAgain(item)">{{item.actName}}</div>
        </div>
      <van-popup v-model="showBottom" position="bottom" :style="{ height: '100%' }">
        <sm_header title='退回' :smHleftEvent='() => {showBottom = false}'></sm_header>
        <van-field class="backReason" style="margin-top: .44rem;" v-model="message" rows="5" autosize
                   type="textarea" maxlength="400" placeholder="请输入退回原因" show-word-limit />
        <div class="largeBtn6" @click="launchEvent(selectBtn, message)">提交</div>
      </van-popup>
    </section>
</template>
<script>
    import venueCard from "./components/venueCard";
    import itemCard from "./components/itemCard";
    import {FinderFrame} from "smart-filer-vue";
    import "smart-filer-vue/lib/index.css";
    import {getCookie} from "tiny-cookie";
    import generalBridge from "@/plugin/generalBridge.js";
    import {Field, Popup} from 'vant';
    export default {
        components: {
            itemCard,
            venueCard,
            FinderFrame,
          [Field.name]: Field,
          [Popup.name]: Popup,
        },
        data () {
            return {
                title: '酒店结算',
                taskId: '',
              message: '',
                showInput: false,
                visible: false,
                isShowTips: false,
              showBottom: false,
              selectBtn: {},
                costInnerItem: {},
                btnList: [{itsActData:{color:null}}],
                headTips: [],
                uploaderSettings: {
                    mappingData: {
                        processId: this.$SmartStorage.get("costItem").proposalId,
                        supplierId: this.$SmartStorage.get("costItem").supplierId,
                        relatedType: "bill",
                        catalog: (this.$SmartStorage.get("costItem").lossStatus && this.$SmartStorage.get("costItem").lossStatus > 0) ?  "损失费文件" : '水单'
                    },
                    filterData: {
                        processId: this.$SmartStorage.get("costItem").proposalId,
                        supplierId: this.$SmartStorage.get("costItem").supplierId,
                        relatedType: (this.$SmartStorage.get("costItem").lossStatus && this.$SmartStorage.get("costItem").lossStatus > 0)? 'settle' : ["bill","bill_reback","SupplierSettle","settle"],
                        catalog: (this.$SmartStorage.get("costItem").lossStatus && this.$SmartStorage.get("costItem").lossStatus > 0) ?  "损失费文件" : '水单'
                    }
                },
                filterData: {
                    processId: this.$SmartStorage.get("costItem").proposalId,
                    supplierId: this.$SmartStorage.get("costItem").supplierId,
                    relatedType: "invoice",
                    catalog: "发票"
                },
                demandData: {
                    itsQuoteExpense: []
                },
                gateway: process.env.VUE_APP_GATEWAY,
                OrderByData: {
                    catalog: 'asc',
                    CreatedOn: 'asc'
                },
                actions: [
                    {actionType: "carame", actionLabel: "拍照"},
                    {actionType: "album", actionLabel: "相册", accepts: "image/*"},
                ],
                tenant: getCookie('tenant') || this.$SmartStorage.get("tenant") || this.$SmartStorage.get("tenant_code")
            }
        },
        created () {
            this.initConfirmCost()
            this.getTips()
        },
        computed: {
          updateAmount () {
            return  this.demandData.quoteAmount-this.demandData.settleAmount >= 0 ?
                this.currencyFormatter(this.demandData.quoteAmount-this.demandData.settleAmount || 0) :
                this.currencyFormatter((0 - (this.demandData.quoteAmount-this.demandData.settleAmount)) || 0)
          },
          updateAmountTxt () {
            return  this.demandData.quoteAmount-this.demandData.settleAmount >= 0 ? '省' : '超'
          },
            showInvoice () {
                return  (['pfizer','viatris', 'crossevent'].includes(this.tenant)) && this.demandData.status >= 100
            },
            showUpdate () {
                return  !(this.demandData.status == 50 &&  this.demandData.proposalStatus == 50 && (['pfizer','viatris'].includes(this.tenant)))
            },
            isLoss () {
              return this.demandData.lossStatus && this.demandData.lossStatus > 0
            },
            fileName () {
              return this.isLoss ? '损失费文件' : this.showUpdate ? '水单' : '上传水单'
            },
            isShowFooter () {
              let result = this.btnList.length > 0 && [42,50,53].includes(this.demandData.status) && this.taskId && this.demandData.isCanEdit
              return result || [52].includes(this.demandData.status)
            }
        },
        methods: {
          currencyFormatter(amount, symbol = '￥', digits = 2) {
            return `${symbol}${Number(amount || 0).toFixed(digits)}`
          },
          initConfirmCost () {
            this.demandData = this.$SmartStorage.get('costItem')
            this.taskId = this.demandData.taskId?this.demandData.taskId:false
            if (['pfizer','viatris'].includes(this.tenant)) {
              if (this.demandData.lossStatus && this.demandData.lossStatus == 21) {
                this.demandData.settleAmount = 0
              }
              this.showInput = (!this.demandData.settleAmount && this.taskId && this.demandData.settleAmount != 0)
            }
            let isQueryTask = this.taskId && [42,50,53].includes(this.demandData.status)
            if(isQueryTask) {
              this.queryTaskActions(this.taskId)
            }
            if ([52].includes(this.demandData.status)) {
              this.btnList = [{actName: '去支付',isNotShow: false,itsActData: {color:null}}]
            }
          },
            closeWebview () {
                this.$router.go(-1)
            },
            costItemDetail (item) {
                this.$SmartStorage.set('costInnerItem', item)
                this.$router.push({ path: "/costItemDetail"});
            },
            // footer
            async queryTaskActions(taskId) { // 获取按钮
                let _this = this;
                let params = {
                    taskId: taskId
                };
                let res = await this.$service.QueryTaskActions(params);
                if (res && res.success) {
                  let btnListCopy = !this.showInput ? res.content : res.content.filter(item => !['退回'].includes(item.actName));
                  btnListCopy.forEach((item) => {
                    item.color = item.itsActData.color
                    let tenantColor = `${_this.tenant}_color`
                    if (item.itsActData[tenantColor]) {
                      item.color = item.itsActData[tenantColor]
                    }
                  })
                  if ([53].includes(this.demandData.status)) {
                    btnListCopy[0].actName = '上传发票'
                  }
                  this.btnList = btnListCopy
                }
            },
            onceAgain(btn) { // 二次提示
                if(btn.actName=="退回") {
                  this.selectBtn = btn
                  this.showBottom = true
                  this.message = ''
                } else if (['去支付'].includes(btn.actName)) {
                  let data = this.demandData
                  let params = {
                    origin: data.origin,
                    payMode: data.payMode,
                    settleAmount: data.settleAmount, //结算总金额
                    prepaymentAmount: data.prepaymentAmount, //预付金额
                    payAmount: data.payAmount, //需要支付的金额
                    expenseId: data.expenseId, //关键字费用ID
                    payStatus: data.payStatus, // 支付状态
                  }
                  this.goPay(params, [20].includes(data.payMode) ? 'cashier' : 'paySelect')
                }  else if (['上传发票'].includes(btn.actName)) {
                  let data = this.demandData
                  let taskData = this.$SmartStorage.get("taskData")
                  this.goPay({origin: data.origin, orderId: taskData.orderId}, 'uploadInvoice')
                } else{
                    let msg = '是否确认对本酒店进行' + btn.actName+'操作'
                    this.$dialog.confirm({
                        message: msg,
                    }).then(() => {
                            this.launchEvent(btn)
                        })
                        .catch(() => {
                        });
                }
            },
            launchEvent(btn,value) {
                switch(btn.actDirective){
                    case "back-settle":
                    case "back-loss":
                        this.rereport(value,btn.actionId)
                        break;
                    case "confirm-settle":
                    case "confirm-loss":
                        this.acceptSettle(btn.actionId)
                        break;
                }
            },
            // commen
            async rereport(value,actionId) { // footerBtn 退回==>流程接口
                let params = {
                    "proposalId": this.demandData.proposalId,
                    "supplierId": this.demandData.supplierId,
                    "taskId": this.taskId,
                    "actionId": actionId,
                    "reason": value,
                    "formData": JSON.stringify({supplierId: this.demandData.supplierId}),
                    "taskData": JSON.stringify({supplierId: this.demandData.supplierId,'rebackReason':  value}),
                    // 提交 1，退回报价 2，退回结算3，退回损失 4
                    "operationType": 3
                }
                let res =await this.$service.creatTask(params);
                this.$store.commit('changeButStatus',true)
                if(res.success) {
                    this.$toast('退回成功')
                    this.$router.back()
                }
            },
            async acceptSettle(actionId) { // 确认结算
                if(!this.demandData.settleAmount && ![0].includes(this.demandData.settleAmount)) {
                    this.$toast('请输入结算金额')
                    return
                }
                let params = {
                    "proposalId": this.demandData.proposalId,
                    "supplierId": this.demandData.supplierId,
                    "settle": this.demandData.settleAmount,
                    "taskId": this.taskId,
                    "actionId": actionId,
                    "OperationType": 'acceptSettle',
                }
                let res = await this.$service.confirmSupplierSettle(params);
                if(res.success) {
                  if (['pfizer'].includes(this.tenant) && [20, 30].includes(res.content.payMode) && this.demandData.isOffline) {
                    this.goPay(res.content, [20].includes(res.content.payMode) ? 'cashier' : 'paySelect')
                  } else {
                    this.$toast('确认结算成功')
                    this.$router.back()
                  }
                }
            },
          async goPay(data, url){
            let params = {
              Data: {
                eventData: {
                  proposalId: this.demandData.proposalId,
                },
                itemData: {},
                tenant_code: this.tenant,
                tenant_id: getCookie('tenant_id'),
                tenant: this.tenant,
                token: getCookie('token'),
              }
            }
            let itemData = {
              proposalId: this.demandData.proposalId,
              itemId: this.demandData.supplierId,
              name: this.demandData.supplierName,
              settle: this.demandData.settleAmount,
              sessionId: this.$SmartStorage.get('sessionId'),
            }
            if (this.$SmartStorage.get('authMobile')) {
              itemData.authMobile = this.$SmartStorage.get('authMobile')
            }
            params.Data.itemData = Object.assign(itemData, data)
            let res = await this.$service.saveCacheData(params)
            if (res && res.success) {
              let ipayURL = `${this.$SmartStorage.get("Uris").Uris["webapp-ipay"]}/${url}?sessionId=${res.content}`
              generalBridge.openWebAppCate(ipayURL)
            }

          },
          async getTips () {
            let miceInfo = this.$SmartStorage.get("eventData")
            let params = {
              collection: "cfg-ivenue-tips",
              filter: {
                tenantCode: this.tenant,
                origin: 'settlePrice',
                typeDictVal: {"$in":[ miceInfo?.typeDictTxt || "*","*"]},
              }
            }
            let res = await this.$service.getsettings(params)
            if (res && res.success) {
              this.headTips = res.content ? res.content.content.tips : []
            }
          }
        }
    }
</script>
<style lang="scss" scoped>
.quotedPriceHead {
  font-size: .25rem;
  text-align: left;
  padding: 0.1rem 0.2rem;
  background: var(--themeColor);
  color: #ffffff;
  font-weight: bold;
}
.headTips {
  background: #F5F5F5;
  margin: .1rem .1rem 0;
  padding: .15rem .18rem;
  font-size: .12rem;
  border-radius: .04rem;
  max-height: 0.2rem;
  overflow: hidden;
  transition: max-height .5s ease;
  p {
    font-size: .12rem;
    text-align: left;
    color: #909399;
  }
  .tipsTitle {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    font-size: .15rem;
    color: #13161B;
    margin-bottom: 0.2rem;
  }
}
.headTips.active{
  max-height: 2rem;
}
.fileTitle {
    text-align: left;
    font-size: .16rem;
    font-weight: bold;
    color: #000;
    padding: .1rem;
    position: relative;
  margin-top: .1rem;
}
.confirmCostFooter {
  position: fixed;
  bottom: 0;
  padding: 0.2rem 0.1rem 0.1rem 0.1rem;
  width: calc(100% - 0.2rem);
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: space-around;
  align-items: center;
  background: #fff;
  .largeBtn2 {
    padding: 0.1rem 0;
    border-radius: 0.04rem;
    font-size: .17rem;
    background: #FFFFFF;
    border: 1px solid #E6E7E9;
    font-weight: 500;
    color: #90939A;
    &:last-child{
      background: var(--themeColor);
      border: 1px solid var(--themeColor);
      color: #FFFFFF;
    }
  }
}
</style>
<style lang="scss">
.confirmCostHead {
  .header-bg {
    opacity: 1 !important;
  }
}
.quotedPrice {
  margin: .08rem .1rem .05rem;
  border-radius: .04rem;
  .van-cell {
    background: #F5F5F5;
    .van-field__label {
      span {
        font-weight: bold;
      }
    }
    .van-field__control {
      text-align: right;
      font-weight: bold;
    }
  }
}
.disabledPrice {
  .van-cell {
    background: #F5F5F5;
    .van-field__label {
      span{
        color: #909399;
      }
    }
    .van-field__control {
      color: #909399;
    }
  }
}
</style>
